import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rwsaudara: {
      id: '',
      NIK: null,
      nama: '',
      tempatLahir: '',
      tglLahir: '',
      aktaMeninggal: null,
      tglMeninggal: null,
      jenisKelamin: '',
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwsaudara = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})
