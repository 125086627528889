<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="6">
        <h2>Data Saudara</h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <rwsaudaraModalAdd />
      </v-col>
    </v-row>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="rwsaudaras"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        :loading="ldata"
        hide-default-footer
        style="text-overflow: ellipsis; white-space: nowrap"
      >
        <template v-slot:top>
          <rwsaudaraModalEdit />
          <rwsaudaraModalHapus />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwsaudaraModalAdd from "@/components/asn/profil/rwsaudara/ModalAdd";
import rwsaudaraModalEdit from "@/components/asn/profil/rwsaudara/ModalEdit";
import rwsaudaraModalHapus from "@/components/asn/profil/rwsaudara/ModalHapus";

import modal_editState from "@/store/asn/rwsaudara/modal_edit";
import modal_hapusState from "@/store/asn/rwsaudara/modal_hapus";
import refreshView from "@/store/asn/rwsaudara/viewRwsaudara";

export default {
  name: "rwsaudara",

  components: {
    rwsaudaraModalAdd,
    rwsaudaraModalEdit,
    rwsaudaraModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    rwsaudaras: [],

    ldata: true,

    //Kebutuhan Edit
    editedIndex: -1,
    hapusIndex: -1,

    headers: [
      { text: "Nama Saudara", align: "start", sortable: false, value: "nama" },
      { text: "Tempat Lahir", value: "tempatLahir" },
      { text: "Tanggal Lahir", value: "tglLahir" },
      { text: "Jenis kelamin", value: "jenisKelamin" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "80px",
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true
      const url = process.env.VUE_APP_ASN + "saudara/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ldata = false
          refreshView.commit("refreshData", false);
          this.rwsaudaras = response.data.mapData.DataKeluarga.dataSaudara;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwsaudaras.indexOf(item);
      this.rwsaudara = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.hapusIndex = this.rwsaudaras.indexOf(item);
      this.rwsaudara = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },
  },
};
</script>
