import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwsaudara: {
      id: '',
      NIK: null,
      nama: '',
      tempatLahir: '',
      tglLahir: '',
      aktaMeninggal: null,
      tglMeninggal: null,
      jenisKelamin: '',
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwsaudara = value
    },
  }
})
